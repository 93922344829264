<template>
  <div
    class="city-choice"
    :class="{ 'is-opened': isOpenSelectCity }"
    @click="closeSelectCity"
  >
    <div class="city-choice__container" @click.stop="">
      <div class="city-choice__title">{{ $t("chooseCity") }}</div>
      <div v-if="cities && selectedCity" class="city-choice__list">
        <a
          v-for="city of cities"
          :key="city.id"
          class="city-choice__city"
          :class="{
            'is-active': city.id === selectedCity.id,
          }"
          @click="closeSelectCity"
          @click.prevent="saveCity(city)"
        >
          {{ city.attributes.name }}
        </a>
      </div>
      <button class="city-choice__button" @click="closeSelectCity">
        Выбрать город
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import { actionTypes as actionTypesCity } from "@/store/modules/city";

export default {
  name: "CitiesList",
  computed: {
    ...mapState(["isOpenSelectCity"]),
    ...mapState("cityModule", { cities: "data", selectedCity: "selectedCity" }),
  },
  created() {
    this.getAllCities().then((cities) => {
      const qsCity = this.$route.query.city_id
        ? parseInt(this.$route.query.city_id)
        : JSON.parse(localStorage.getItem("selectedCity")) || 2;
      const city = cities.find((c) => c.id === qsCity);
      this.$router.onReady(() => {
        if (!this.$route.query.city_id) {
          this.$router.push({
            params: { lang: this.$i18n.locale || "ru" },
            query: { city_id: city?.id || city || 2 },
          });
        }
      });
      this.saveChoosedCity(city);
    });
  },
  methods: {
    ...mapActions({ closeSelectCity: actionTypes.changeIsOpenSelectCity }),
    ...mapActions("cityModule", {
      getAllCities: actionTypesCity.loadAllCities,
      saveChoosedCity: actionTypesCity.changeSelectedCity,
    }),
    saveCity(city) {
      console.log(city);
      this.saveChoosedCity(city);
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: { ...this.$route.query, city_id: city.id || 2 },
      });
    },
  },
};
</script>
